.home-icon {
    width: 200px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}

.services-container {
    background-color: white; 
    padding: 100px 10vw;
    font-size: 1.5rem;
}

.services-container ul {
    list-style-type: none;
    padding: 0;
}

.welcome-container {
    color: white;
    font-size: 2rem;
    font-weight: 500;
    padding: 100px;
}

.principles-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.contact-item {
    background-color: var(--color-d);
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.contact-icon {
    width: 50px;
    margin-right: 10px;
}

@media screen and (max-width: 768px) {
    .contact-item {
        font-size: 1rem;
    }

    #quienes-somos {
        padding: 20px;
    }
    
}