.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  min-height: 100vh;
  height: auto;
  background-color: var(--color-d);
  transition: transform 0.3s ease;
  transform: translateX(-250px);
  z-index: 999; /* Asegúrate de que esté por encima del overlay */
  box-shadow: none; /* Elimina la sombra cuando el drawer está oculto */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color semitransparente */
  z-index: 998; /* Asegúrate de que esté detrás del drawer */
  display: none; /* Oculta inicialmente el overlay */
}

.drawer-title {
  color: var(--color-g);
}

.overlay.open {
  display: block; /* Muestra el overlay cuando el drawer está abierto */
}

.drawer.open {
  transform: translateX(0);
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.5); /* Agregamos la sombra solo cuando el drawer está abierto */
}

.icon {
  fill: white;
  min-width: 40px;
  min-height: 40px;
  
}

.icon-app {
  max-width: 150px;
  max-height: 150px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease; /* Agregamos transición para que la animación sea suave */
}

.icon-app:hover {
  animation: wobble 0.5s ease 1; /* Aquí aplicamos la animación de tambaleo */
}

@keyframes wobble {
  0% { transform: translateX(0%); }
  15% { transform: translateX(-25%) rotate(-5deg); }
  30% { transform: translateX(20%) rotate(3deg); }
  45% { transform: translateX(-15%) rotate(-3deg); }
  60% { transform: translateX(10%) rotate(2deg); }
  75% { transform: translateX(-5%) rotate(-1deg); }
  100% { transform: translateX(0%); }
}

.rotate {
  transform: rotate(90deg);
}

.toggle-btn {
  position: absolute;
  top: 10px;
  right: -60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.bottom-space {
  min-height: 10vh;
}

.drawer-options {
  
}

.drawer-content {
  padding: 20px;
  overflow-y: auto; /* Agregar desplazamiento vertical cuando el contenido es demasiado grande */
  max-height: 100vh; /* Restar el espacio ocupado por el logotipo y otros elementos fijos */
}
.drawer-content::-webkit-scrollbar {
  width: 0; /* Oculta el scrollbar en navegadores WebKit (Chrome, Safari, etc.) */
}

.content-container {
  position: relative;
}

.content-container.overlay {
  pointer-events: none;
}

.content-container.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  z-index: 999; /* Asegura que esté por encima del contenido */
}

