.slideshow {
    position: relative;
    width: 80vw;
    max-width: 90vw;
    height: 80vh;
    margin: auto;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center; /* Centra verticalmente el contenido */
    justify-content: center; /* Centra horizontalmente el contenido */
  }
  
  .slide {
    display: none;
    width: 100%;
  }
  
  .slide.active {
    display: block;
  }
  
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .nav-button {
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    user-select: none;
    transition: 0.6s ease;
  }
  
  .prev {
    left: 0;
    border-radius: 0 3px 3px 0;
  }
  
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  .nav-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }
  
  .dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dot.active {
    background-color: #717171;
  }
  
  /* Media queries para hacer el slideshow responsive */
  @media (max-width: 600px) {
    .slideshow {
      max-width: 100%;
      height: 100vw;
    }
  
    .nav-button {
      padding: 12px;
      font-size: 16px;
    }
  
    .dot {
      width: 10px;
      height: 10px;
      margin: 0 3px;
    }
  }