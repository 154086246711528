.general-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 85vh;
  padding: 20px;
  width: 98vw;
  border-radius: 20px;
  background-color: var(--color-b);
}

.content {
  width: 100%;
  height: 100%;
  word-wrap: break-word;
  text-align: justify;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .general-container {
    flex-direction: column;
  }
  
}

.centred {
    min-width: 50%;
    width: 90%;
    margin: 0 auto;
}