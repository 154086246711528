.space-div {
  height: 40px;
}

.lock-scroll {
  overflow: hidden;
}

.h1 {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.h3 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.h4 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.h5 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.h1, .h2, .h3, .h4, .h5{
    font-family: "Roboto", sans-serif;
    color: var(--color-a);
    
}

.left {
    text-align: left;
    margin-left: 2em;
}

.right {
    text-align: right;
    margin-right: 2em;
}

.left-buttons {
    text-align: left;
    margin-left: 4em;
    margin-top: 1em;
}

@media screen and (max-width: 600px) {
    .left {
        margin-left: 2em;
    }
    .left-buttons {
        margin-left: 2em;
    }
    
}