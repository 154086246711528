.my-footer {
    background-color: var(--color-f);
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }

  .flag {
    width: 50px;
    filter: grayscale(100%);
  }

  .flag:hover {
    filter: grayscale(0%);
  }

  