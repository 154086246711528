.drawerItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: var(--color-a);
    border-radius: 10px;
}

.drawerItem:hover{
    background-color: #333;
    color: white;
}

a {
    color: white;
    text-decoration: none;
}

.drawerItem.active {
    background-color: var(--color-c); /* Cambia el color de fondo cuando está activo */
    color: white;
  }