:root {
  --color-a: #1ed0cf;
  --color-b: #DBE9EE;
  --color-c: #4A6FA5;
  --color-d: #131529;
  --color-e: #c4ffc9;
  --color-f: #5c5e74;
  --color-g: #166088;
  --color-h: #C0D6DF;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
